import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import { FormCellValue } from "../../../../shared/api/dataCollectionTypes";
import { EntityOptionFieldType } from "../../../../shared/api/dictionaryTypes";
import DataSubmissionStatusTag from "../../../../shared/components/dataCollection/DataSubmissionStatusTag";
import DataSubmissionForm from "../../../../shared/components/dataCollection/submissionForms/DataSubmissionForm";
import {
  addFormCellValueAction,
  EditedFormValuesState,
  getFormUpdatesPerBlock,
  getInitialEditedFormValuesState,
  isEditedFormValuesStateEmpty,
} from "../../../../shared/components/dataCollection/submissionForms/editedFormValuesState";
import { ImportErrorsAlert } from "../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportErrorsAlert";
import ImportSubmissionBlockToExcelDialog from "../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportSubmissionBlockToExcelDialog";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import useToggleState from "../../../../shared/hooks/useToggleState";
import { logError } from "../../../../shared/logging";
import { defined } from "../../../../shared/utilities/typeHelper";
import { api } from "../../../api/client";
import {
  DataCollectionSubmissionDetails,
  ImportSubmissionBlockInputResponse,
} from "../../../api/types/dataCollectionTypes";
import { ClientDictionaries } from "../../../api/types/settingsTypes";
import { useClientContext } from "../../../contexts/ClientContext";
import { pageRoutes } from "../../../routes";
import PageHeader from "../../common/PageHeader";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

const saveDataSubmissionLayoutBlockInput = withErrorHandling(api.dataCollection.saveDataSubmissionLayoutBlockInput);

const dictionariesPropMap: Partial<Record<EntityOptionFieldType, keyof ClientDictionaries>> = {
  Country: "countries",
  State: "states",
  Currency: "currencies",
  IndustryOrSector: "industriesAndSectors",
  Geography: "geographies",
};

const DataSubmissionPage = () => {
  const { id } = useParams();
  const { clientCode } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const [formRenderKey, toggleFormRenderKey] = useToggleState(false);
  const [isSaving, setSaving] = useState(false);
  const [editedFormValues, setEditedFormValues] = useState<EditedFormValuesState>(getInitialEditedFormValuesState());
  const [showSubmitConfirmationDialog, setShowSubmitConfirmationDialog] = useState(false);
  const [showImportDataDialog, setShowImportDataDialog] = useState(false);
  const [importErrors, setImportErrors] = useState<string[]>([]);

  const getDataSubmissionDetails = useCallback(() => api.dataCollection.getDataSubmissionDetails(defined(id)), [id]);

  const [
    dataSubmission,
    fetchSubmissionError,
    { isFetching: isFetchingSubmission, setData: setDataSubmission, fetch: fetchSubmission },
  ] = useFetch(getDataSubmissionDetails);

  const [dictionaries, fetchDictionariesError, { isFetching: isFetchingDictionaries }] = useFetch(
    api.dictionaries.getDictionaries
  );

  const handleBlockCellValueEdit = useCallback((blockId: string, formCellValue: FormCellValue) => {
    setEditedFormValues(addFormCellValueAction(blockId, formCellValue));
  }, []);

  const globalDictionaries = useMemo(
    () =>
      dictionaries
        ? Object.fromEntries(
            Object.entries(dictionariesPropMap).map(([key, propName]) => [key, dictionaries[propName]])
          )
        : {},
    [dictionaries]
  );

  const isSubmissionEditable = useMemo(() => dataSubmission?.status === "Pending", [dataSubmission?.status]);

  const fetchError = fetchSubmissionError || fetchDictionariesError;
  if (fetchError) {
    logError(fetchError, "[DataSubmissionPage] getDataSubmissionDetails");
    return <DataLoadingFailed title="Could not load data submission details" />;
  }

  const isFetching = isFetchingSubmission || isFetchingDictionaries;
  if (isFetching || dataSubmission === undefined) {
    return <InlineLoader />;
  }

  const handleSubmitClick = () => {
    setShowSubmitConfirmationDialog(true);
  };

  const handleSaveClick = async () => {
    const formUpdates = getFormUpdatesPerBlock(editedFormValues);
    let updatedSubmission: DataCollectionSubmissionDetails | undefined = undefined;

    setSaving(true);

    for (const [blockId, payload] of formUpdates) {
      const [resp, error] = await saveDataSubmissionLayoutBlockInput(defined(id), blockId, payload);

      if (error) {
        logError(error, `[DataSubmissionPage] saveDataSubmissionLayoutBlockInput (${id}:${blockId})`);
        sendNotificationError("Failed to save form values");
        continue;
      }

      updatedSubmission = resp;
    }

    setSaving(false);

    if (updatedSubmission) {
      setEditedFormValues(getInitialEditedFormValuesState());
      setDataSubmission(updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleSubmitted = () => {
    setShowSubmitConfirmationDialog(false);
    fetchSubmission();
  };

  const handleImport = (importResp: ImportSubmissionBlockInputResponse) => {
    setShowImportDataDialog(false);

    if (importResp.errors.length > 0) {
      setImportErrors(importResp.errors);
    }

    if (importResp.updatedSubmission !== undefined) {
      setDataSubmission(importResp.updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleCancelClick = () => {
    setEditedFormValues(getInitialEditedFormValuesState());
    toggleFormRenderKey();
  };

  const handleImportDataClick = () => {
    setShowImportDataDialog(true);
  };

  const importableBlock = dataSubmission.blockContents.find((block) => block.capabilities.includes("ExcelImport"));

  const isSaveDisabled = isEditedFormValuesStateEmpty(editedFormValues);
  const isSubmitDisabled = !isSaveDisabled || isSaving;
  const isImportDisabled = !isSaveDisabled || isSaving;

  return (
    <>
      <PageHeader
        title={dataSubmission.requestName}
        titleAdornment={<DataSubmissionStatusTag status={dataSubmission.status} />}
        subtitle="Data Submission"
        backButtonLink={`${clientCode}/${pageRoutes.dataCollection}`}
      >
        {isSubmissionEditable && (
          <Stack direction="row" spacing={1}>
            {importableBlock !== undefined && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ImportDataIcon />}
                disabled={isImportDisabled}
                onClick={handleImportDataClick}
              >
                Import Data
              </Button>
            )}
            <Button variant="text" color="secondary" disabled={isSaveDisabled} onClick={handleCancelClick}>
              Cancel
            </Button>
            <LoadingButton variant="contained" loading={isSaving} disabled={isSaveDisabled} onClick={handleSaveClick}>
              Save
            </LoadingButton>
            <Button variant="outlined" disabled={isSubmitDisabled} onClick={handleSubmitClick}>
              Submit
            </Button>
          </Stack>
        )}
      </PageHeader>

      <ImportErrorsAlert errors={importErrors} onClose={() => setImportErrors([])} />

      <DataSubmissionForm
        key={formRenderKey.toString()}
        recipientObjectId={dataSubmission.recipientObjectId}
        layout={dataSubmission.layout}
        blockContents={dataSubmission.blockContents}
        isSubmissionEditable={isSubmissionEditable}
        globalDictionaries={globalDictionaries}
        metricExtensionsService={{
          getMetricExtensions: api.dataCollection.getMetricExtensionsForObject,
          createMetricExtension: api.dataCollection.createMetricExtension,
        }}
        onBlockCellValueEdit={handleBlockCellValueEdit}
      />

      <SubmitConfirmationDialog
        submission={dataSubmission}
        open={showSubmitConfirmationDialog}
        onClose={() => setShowSubmitConfirmationDialog(false)}
        onSubmitted={handleSubmitted}
      />

      {showImportDataDialog && importableBlock !== undefined && (
        <ImportSubmissionBlockToExcelDialog<DataCollectionSubmissionDetails>
          submissionImportService={{
            exportSubmissionBlockInputToExcel: api.dataCollection.exportSubmissionBlockInputToExcel,
            importSubmissionBlockInputFromExcel: api.dataCollection.importSubmissionBlockInputFromExcel,
          }}
          submissionId={dataSubmission.id}
          blockId={importableBlock.blockId}
          onClose={() => setShowImportDataDialog(false)}
          onImport={handleImport}
        />
      )}
    </>
  );
};

export default DataSubmissionPage;
