import MoneyIcon from "@mui/icons-material/AttachMoney";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import { Fund } from "../../api/types/commonApiTypes";

interface Props {
  selectedFundId: string;
  funds: Fund[];
  onChange: (fundId: string) => void;
  width?: string | number;
  disabled?: boolean;
}

const FundSelect = ({ selectedFundId, funds, onChange, width, disabled }: Props) => {
  return (
    <Select
      value={selectedFundId}
      onChange={(e) => onChange(e.target.value)}
      startAdornment={
        <InputAdornment position="start">
          <MoneyIcon />
        </InputAdornment>
      }
      disabled={disabled}
      sx={{ width: width ?? "14rem" }}
    >
      {funds.map((fund) => (
        <MenuItem key={fund.id} value={fund.id}>
          {fund.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FundSelect;
