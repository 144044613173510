import { Alert, AlertTitle, Box, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse } from "../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../shared/components/filters/RecordCounter";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import SearchField from "../../../shared/components/inputs/SearchField";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import FundSelect from "../common/FundSelect";
import TabularReportGrid from "../common/reports/TabularReportGrid";
import { searchTabularReportRows } from "../common/reports/tabularReportFiltering";

const PortfolioReportView = () => {
  const [selectedFundId, setSelectedFundId] = useState<string>();
  const [searchValue, setSearchValue] = useState("");

  const [funds, fetchFundsError] = useFetch(api.portfolio.getFunds, (data) => setSelectedFundId(data[0]?.id));

  const runReport = useCallback(() => {
    if (!selectedFundId) {
      return Promise.resolve(createApiResponse(undefined));
    }

    return api.portfolio.runReport({ fundId: selectedFundId });
  }, [selectedFundId]);

  const [report, fetchReportError, { isFetching: isRunningReport }] = useFetch(runReport);

  const filteredRows = useMemo(
    () => (report?.rows === undefined ? [] : searchTabularReportRows(report.rows, searchValue)),
    [report?.rows, searchValue]
  );

  if (funds === undefined) {
    return <InlineLoader />;
  }

  if (fetchFundsError) {
    logError(fetchFundsError, "[PortfolioReportView] getFunds");
    return <DataLoadingFailed title="Could not get available funds" />;
  }

  if (funds.length === 0) {
    return (
      <Alert severity="info">
        <AlertTitle>No investment funds available</AlertTitle>
      </Alert>
    );
  }

  if (fetchReportError) {
    return (
      <Alert severity="error">
        <AlertTitle>Report execution error</AlertTitle>
        {fetchReportError.message}
      </Alert>
    );
  }

  return (
    <>
      {selectedFundId !== undefined && funds !== undefined && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <FundSelect funds={funds} selectedFundId={selectedFundId} onChange={setSelectedFundId} width="16rem" />
            <RecordCounter
              records={filteredRows.length}
              totalRecords={report?.rows.length}
              hide={report === undefined}
            />
          </Stack>

          <SearchField onSearch={setSearchValue} disabled={report === undefined || report.rows.length === 0} />
        </Box>
      )}

      {report === undefined && isRunningReport && <InlineLoader />}

      {report !== undefined && (
        <TabularReportGrid
          columns={report.columns}
          allRows={report.rows}
          rows={filteredRows}
          loading={isRunningReport}
          columnIdWithIcon="InvestmentName"
        />
      )}
    </>
  );
};

export default PortfolioReportView;
