import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, Drawer, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import CloseIconButton from "../../../shared/components/CloseIconButton";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { authCookieExists } from "../../../shared/utilities/cookies";
import { api } from "../../api/client";
import { ImpersonationInvestor } from "../../api/types/impersonationTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { useLocalization } from "../../hooks/useLocalization";
import { cancelImpersonation, restoreImpersonationSearchParams } from "../../impersonation/impersonationHandler";
import { UserActions } from "../../store/state/user/actions";
import { identityEmailSelector, identityNameSelector } from "../../store/state/user/selectors";
import { loadUserInfo } from "../../store/state/user/thunks";
import { AppDispatch } from "../../store/store";
import ImpersonationSearchPanel from "./ImpersonationSearchPanel";
import ImpersonationHelpPopup from "./impersonationHelpPopup";

const filterInvestorsWithContacts = (investors: ImpersonationInvestor[] | undefined) =>
  (investors ?? []).filter((investor) => investor.contacts.length > 0);

const ImpersonationPanel = () => {
  const { clientCode } = useClientContext();
  const userName = useSelector(identityNameSelector);
  const email = useSelector(identityEmailSelector);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locale = useLocalization();
  const panelRef = useRef<HTMLElement>();

  const [showInvestorList, setInvestorListDisplayed] = useState(false);
  const [helpPopupAnchorEl, setHelpPopupAnchorEl] = useState<HTMLElement | undefined | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const [investors, setInvestors] = useState<ImpersonationInvestor[]>([]);

  const [impersonationData, fetchError] = useFetch(api.impersonation.getImpersonationOptions, (data) => {
    setInvestors(filterInvestorsWithContacts(data.investors));
  });

  useEffect(() => {
    restoreImpersonationSearchParams(searchParams);
  }, [searchParams, location]);

  const onImpersonationCancel = () => {
    cancelImpersonation();
    if (!authCookieExists()) {
      navigate(`/${clientCode}/loggedoutImpersonation`);
    } else {
      dispatch(UserActions.UpdateImpersonation(false));
      dispatch(loadUserInfo(clientCode));
      navigate(`/${clientCode}`);
    }
  };

  const toggleUserList = () => {
    setInvestorListDisplayed((show) => !show);
  };

  const closeInvestorList = () => {
    setInvestorListDisplayed(false);
  };

  const panelTextColor = "#FFF";

  return (
    <Box
      ref={panelRef}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        height: "3rem",
        left: "50%",
        bottom: "-1.5rem",
        position: "fixed",
        transform: "translate(-50%, -50%)",
        width: "auto",
        px: "2rem",
        py: "1rem",
        bgcolor: "#2196F3",
        gap: theme.spacing(1),
        borderRadius: "4px 4px 0px 0px",
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.06)",
      })}
    >
      <Typography color="white">{locale.impersonation.inspect_label}</Typography>

      <Select
        sx={{
          bgcolor: "#0B79D0",
          color: panelTextColor,
          maxWidth: "262px",
          maxHeight: "32px",
        }}
        onOpen={toggleUserList}
        open={false}
        displayEmpty
        defaultValue={userName}
        renderValue={() => userName}
      >
        <MenuItem value={userName} disabled>
          {userName}
        </MenuItem>
      </Select>

      {helpPopupAnchorEl && (
        <ImpersonationHelpPopup
          onClose={() => {
            setHelpPopupAnchorEl(null);
          }}
          anchorEl={helpPopupAnchorEl}
        />
      )}

      <IconButton
        sx={{ color: panelTextColor }}
        onClick={() => {
          setHelpPopupAnchorEl(panelRef.current);
        }}
      >
        <HelpOutlineIcon fontSize="medium" />
      </IconButton>

      <Button
        sx={{ color: panelTextColor, borderColor: panelTextColor }}
        variant="outlined"
        onClick={onImpersonationCancel}
      >
        Stop Inspect
      </Button>

      <Drawer
        anchor="right"
        open={showInvestorList}
        sx={(theme) => ({
          "& .MuiDrawer-paper": {
            p: 0,
            width: theme.spacing(78),
          },
        })}
      >
        <Stack height="100%" display="flex" flexDirection="column">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              pl: 2,
              pr: 1,
              py: 2,
              gap: 2,
            }}
          >
            <Typography variant="subtitle1"> User </Typography>
            <Typography color="text.secondary">{`${userName} (${email})`}</Typography>
            <CloseIconButton
              onClick={closeInvestorList}
              color="secondary"
              sx={{ marginLeft: "auto" }}
              iconProps={{ fontSize: "medium" }}
            />
          </Box>

          {impersonationData && !fetchError && <ImpersonationSearchPanel email={email} investors={investors} />}
        </Stack>

        {!!fetchError && <DataLoadingFailed />}
      </Drawer>
    </Box>
  );
};

export default ImpersonationPanel;
