import { Alert, AlertTitle } from "@mui/material";
import { useMemo } from "react";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { ReportColumn, TabularReportRow } from "../../../api/types/portfolioTypes";
import { getColumnDefinitions, getRows, TabularReportRowModel } from "./tabularReportGridDataProvider";

interface Props {
  columns: ReportColumn[];
  rows: TabularReportRow[];
  allRows: TabularReportRow[];
  loading?: boolean;
  columnIdWithIcon?: string;
}

const TabularReportGrid = ({ columns, rows, allRows, loading, columnIdWithIcon }: Props) => {
  const gridColumns = useMemo(
    () => getColumnDefinitions(columns, allRows, columnIdWithIcon),
    [columns, allRows, columnIdWithIcon]
  );
  const gridRows = useMemo(() => getRows(rows), [rows]);

  if (gridColumns.length === 0) {
    return (
      <Alert severity="error">
        <AlertTitle>Report configuration error</AlertTitle>
        Report returned no columns
      </Alert>
    );
  }

  return (
    <DataGrid<TabularReportRowModel>
      columns={gridColumns}
      rows={gridRows}
      loading={loading}
      noRowsText="No data"
      disableAggregation
      disableColumnPinning
      disableColumnReorder
      disableColumnSorting
      noScrollableContainer
    />
  );
};

export default TabularReportGrid;
