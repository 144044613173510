import { Avatar, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import EntriliaLogo from "../../../../shared/images/greylogo.png";
import { ReportCell, ReportColumn, TabularReportRow } from "../../../api/types/portfolioTypes";

export interface TabularReportRowModel {
  id: string;
  cells: Record<string, ReportCell>;
}

export const getRows = (rows: TabularReportRow[]): TabularReportRowModel[] =>
  rows.map((row, index) => ({
    id: `row_${index}`,
    cells: row,
  }));

const getColumnDefinition = (
  column: ReportColumn,
  rows: TabularReportRow[],
  columnIdWithIcon?: string
): GridColDef<TabularReportRowModel> => {
  // We can't get DataType from the column
  const rowTypes = rows.map((row) => row[column.id]?.value?.valueType);
  const rowType = rowTypes.find((type) => type !== undefined);
  const alignRight = rowType === "Integer" || rowType === "Decimal" || rowType === "Long" || rowType === "DateTime";

  // TODO this should come from API
  const hasIcon = columnIdWithIcon && column.id.startsWith(columnIdWithIcon);

  return {
    field: column.id,
    headerName: column.title || column.id,
    flex: 1,
    minWidth: hasIcon ? 184 : 160,
    align: alignRight ? "right" : "left",
    headerAlign: alignRight ? "right" : "left",
    renderCell: ({ row }) => {
      const cell = row.cells[column.id];
      if (cell === undefined) {
        return null;
      }

      if (hasIcon) {
        const logoUrl = cell.properties?.StartIcon || EntriliaLogo;
        return (
          <>
            <Avatar
              src={logoUrl}
              variant="rounded"
              alt="logo"
              slotProps={{ img: { sx: { objectFit: "contain" } } }}
              sx={{ width: 24, height: 24 }}
            />
            <Typography ml={1}>{cell.formattedValue}</Typography>
          </>
        );
      }

      return cell.formattedValue;
    },
  };
};

export const getColumnDefinitions = (
  columns: ReportColumn[],
  rows: TabularReportRow[],
  columnIdWithIcon?: string
): GridColDef<TabularReportRowModel>[] => columns.map((col) => getColumnDefinition(col, rows, columnIdWithIcon));
