import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Grid2, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo, useState } from "react";
import { distinct, groupByToMap } from "../../../shared/utilities/arrayHelper";
import { ImpersonationContact, ImpersonationInvestor } from "../../api/types/impersonationTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { viewAs } from "../../impersonation/impersonationHandler";
import ImpersonationInvestorList from "./ImpersonationInvestorList";

const doContactsMatchFilterValue = (contacts: ImpersonationContact[], filterValue: string) =>
  contacts.some(
    (contact) => contact.email.toLowerCase().includes(filterValue) || contact.name.toLowerCase().includes(filterValue)
  );

const filterInvestors = (investors: ImpersonationInvestor[], filterText: string): ImpersonationInvestor[] => {
  if (filterText.trim() === "") {
    return investors;
  }

  const filterValue = filterText.trim().toLowerCase();

  return investors.filter(
    (inv) =>
      inv.title.toLowerCase().includes(filterValue) ||
      inv.fundName.toLowerCase().includes(filterValue) ||
      doContactsMatchFilterValue(inv.contacts, filterValue)
  );
};

interface Props {
  email: string;
  investors: ImpersonationInvestor[];
}

const ImpersonationSearchPanel = ({ email, investors }: Props) => {
  const { impersonationFundName } = useClientContext();

  const investorsByFundName = useMemo(() => groupByToMap(investors, (investor) => investor.fundName), [investors]);

  const fundNames = useMemo(
    () => distinct(investors.map((inv) => inv.fundName).filter(Boolean)).sort((a, b) => a.localeCompare(b)),
    [investors]
  );

  const existingFundName = fundNames.includes(impersonationFundName || "") ? impersonationFundName : undefined;

  const [currentFundName, setCurrentFundName] = useState<string>(existingFundName || "");

  const [investorFilterText, setInvestorFilterText] = useState("");

  const handleImpersonationView = (email: string) => {
    viewAs(email, currentFundName || "");
  };

  const handleFundChange = (fundName: string) => {
    setCurrentFundName(fundName);
    setInvestorFilterText("");
  };

  const investorsOfFund = currentFundName ? (investorsByFundName.get(currentFundName) ?? []) : [...investors];

  const filteredInvestors = filterInvestors(investorsOfFund, investorFilterText);

  const searchOptionsList = investorsOfFund
    .flatMap((investor) => investor.contacts.map((ct) => ct.name))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort()
    .map((contact) => ({ label: contact }));

  return (
    <>
      <Stack spacing={3}>
        <Grid2 container width="100%">
          <Grid2 size="grow" px={2}>
            <Autocomplete
              disablePortal
              freeSolo
              value={investorFilterText}
              sx={{ backgroundColor: grey[200] }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: <SearchIcon />,
                    },
                  }}
                />
              )}
              onInputChange={(_, value) => setInvestorFilterText(value)}
              options={searchOptionsList || []}
            />
          </Grid2>

          <Grid2 size={5} px={2}>
            <Select
              sx={(theme) => ({
                width: "100%",
                "&.MuiMenu-list": {
                  py: theme.spacing(0.5),
                },
              })}
              onChange={(e) => handleFundChange(e.target.value)}
              value={currentFundName}
              displayEmpty
            >
              <MenuItem value="">All Funds</MenuItem>
              {fundNames.map((fund) => (
                <MenuItem key={fund} value={fund}>
                  {fund}
                </MenuItem>
              ))}
            </Select>
          </Grid2>
        </Grid2>

        <Grid2 container width="100%" pb={2}>
          <Grid2 size={5.5} px={7}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid2>

          <Grid2 size="grow">
            <Typography variant="subtitle1">Email</Typography>
          </Grid2>
        </Grid2>
      </Stack>

      <Box sx={{ flex: 1, overflow: "auto" }}>
        <ImpersonationInvestorList
          handleImpersonationView={handleImpersonationView}
          contactEmail={email}
          filterText={investorFilterText}
          investors={filteredInvestors}
          showFundName={!currentFundName}
        />
      </Box>
    </>
  );
};

export default ImpersonationSearchPanel;
