import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { useClientContext } from "../../contexts/ClientContext";
import SupportInfo from "./SupportInfo";

const SupportInfoHelp = () => {
  const { portalSettings } = useClientContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleHelpClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      {(portalSettings?.supportEmail || portalSettings?.supportPhone) && (
        <IconButton color="inherit" sx={{ ml: 1 }} onClick={handleHelpClick}>
          <HelpOutlineIcon />
        </IconButton>
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: 0,
              overflow: "hidden",
            },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "8px",
              position: "relative",
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            }}
          >
            <SupportInfo email={portalSettings?.supportEmail} phone={portalSettings?.supportPhone} />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SupportInfoHelp;
