import { TabularReportRow } from "../../../api/types/portfolioTypes";

const rowMatchesSearch = (row: TabularReportRow, searchTerm: string): boolean =>
  Object.values(row).some(
    (cellValue) =>
      typeof cellValue.value?.value === "string" && cellValue.formattedValue.toLowerCase().includes(searchTerm)
  );

export const searchTabularReportRows = (allRows: TabularReportRow[], value: string): TabularReportRow[] => {
  const searchTerm = value.toLowerCase().trim();
  if (searchTerm === "") {
    return allRows;
  }

  return allRows.filter((row) => rowMatchesSearch(row, searchTerm));
};
