import { useState } from "react";
import { ImpersonationInvestor } from "../../api/types/impersonationTypes";
import ImpersonationInvestorSection from "./ImpersonationInvestorSection";

interface Props {
  contactEmail: string;
  investors: ImpersonationInvestor[];
  filterText: string;
  handleImpersonationView: (email: string) => void;
  showFundName?: boolean;
}

const ImpersonationInvestorList = ({
  contactEmail,
  investors,
  handleImpersonationView,
  filterText,
  showFundName,
}: Props) => {
  const [expandedInvestor, setExpandedInvestor] = useState<string | undefined>(
    () => investors.find((investor) => investor.contacts.find((contact) => contact.email === contactEmail))?.title
  );

  return (
    <>
      {investors.map((investor) => (
        <ImpersonationInvestorSection
          key={`${investor.title}_${investor.fundName}`}
          investor={investor}
          filterText={filterText}
          contactEmail={contactEmail}
          expandedInvestor={expandedInvestor}
          onExpandInvestor={setExpandedInvestor}
          onContactViewClick={handleImpersonationView}
          showFundName={showFundName}
        />
      ))}
    </>
  );
};

export default ImpersonationInvestorList;
