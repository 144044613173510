import PersonIcon from "@mui/icons-material/PersonOutline";
import { Avatar, Button, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { balanceFieldSet, getBalanceFields } from "../../../../../shared/components/balances/balanceFieldset";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import HorizontalSwiper from "../../../../../shared/components/HorizontalSwiper";
import { logError } from "../../../../../shared/logging";
import { useClientContext } from "../../../../contexts/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import {
  selectDashboardBalance,
  selectDashboardError,
  selectDashboardLoading,
} from "../../../../store/state/dashboard/selectors";
import { cardHeaderPropsDefault } from "../../common/helpers";
import NoDataResult from "../../common/NoDataResult";
import BalanceCard from "./BalanceCard";
import BalanceMobilePage from "./BalanceMobilePage";
import EffectiveReportingDate from "./EffectiveReportingDate";
import { BalanceCardData } from "./types";

const BalanceWidgetSkeleton = () => {
  return (
    <Stack>
      <CardHeader
        sx={{ ...cardHeaderPropsDefault, pb: 2 }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={20} width="40%" />}
        subheader={<Skeleton animation="wave" height={14} width="80%" />}
      />
      <Skeleton sx={{ height: 260, mx: 2, borderRadius: 1 }} animation="wave" variant="rectangular" />
    </Stack>
  );
};

interface Props {
  reportingDate?: Date;
}

const replaceIfEmpty = (value: string | undefined) => value || "-";

const BalanceMobileWidget = ({ reportingDate }: Props) => {
  const isBalanceLoading = useSelector(selectDashboardLoading);
  const balanceDataResponse = useSelector(selectDashboardBalance);
  const balanceErrorMsg = useSelector(selectDashboardError);
  const balanceLocale = useLocalization().dashboard.balance;

  const { clientCode } = useClientContext();

  const [openBalancePage, setOpenBalancePage] = useState(false);

  const balanceCardsData = useMemo(() => {
    if (balanceDataResponse === undefined) {
      return undefined;
    }

    const visibleFields = getBalanceFields({
      exclude: [balanceFieldSet.investorName.guid, balanceFieldSet.communicationGroupName.guid],
      clientCode,
    }).filter((field) => !field.config.hidden);

    const result: BalanceCardData[] = (balanceDataResponse.balanceData ? balanceDataResponse.balanceData.data : []).map(
      (row) => {
        const fundName = replaceIfEmpty(row[balanceFieldSet.communicationGroupName.guid]);
        const fundReportingDate = balanceDataResponse.fundReportingDates[fundName];

        return {
          investorName: replaceIfEmpty(row[balanceFieldSet.investorName.guid]),
          fundName: fundName,
          reportingDate: fundReportingDate && new Date(fundReportingDate),
          fields: visibleFields.map((field) => {
            const config = field.config;
            const value = row[field.guid];
            const currencyCode = row[balanceFieldSet.currencyCode.guid] ?? "";
            const valueFormatted = replaceIfEmpty(config?.format ? config.format(value, currencyCode) : value);
            return [config?.title || field.guid, valueFormatted];
          }),
        };
      }
    );

    return result;
  }, [balanceDataResponse, clientCode]);

  if (balanceErrorMsg) {
    logError(balanceErrorMsg, "BalanceWidget");
  }

  const onBalancePageClose = () => {
    setOpenBalancePage(false);
  };

  const isLoading =
    (isBalanceLoading || (balanceCardsData === undefined && reportingDate !== undefined)) && !balanceErrorMsg;

  const BalanceDisplayData = () => {
    if (!!balanceErrorMsg || isBalanceLoading || balanceCardsData === undefined) {
      return null;
    }

    if (balanceCardsData.length === 0 || reportingDate === undefined) {
      return <NoDataResult text={balanceLocale.no_data} />;
    }

    return (
      <HorizontalSwiper
        items={balanceCardsData.map((bc, i) => (
          <BalanceCard
            key={"bc" + i}
            investorName={bc.investorName}
            title={bc.fundName}
            date={bc.reportingDate}
            fields={bc.fields}
          />
        ))}
      />
    );
  };

  return (
    <Stack>
      {isLoading ? (
        <BalanceWidgetSkeleton />
      ) : (
        <>
          <CardHeader
            sx={cardHeaderPropsDefault}
            avatar={
              <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} aria-label="recipe">
                <PersonIcon sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
              </Avatar>
            }
            action={
              <Button
                variant="text"
                disabled={!!balanceErrorMsg || balanceCardsData === undefined}
                onClick={() => setOpenBalancePage(true)}
              >
                {balanceLocale.actions.view}
              </Button>
            }
            title={
              <Typography variant="h6" color="textPrimary">
                {balanceLocale.widget_name}
              </Typography>
            }
            subheader={<EffectiveReportingDate reportingDate={reportingDate} dateColor="textSecondary" />}
          />
          <BalanceDisplayData />
        </>
      )}

      {!isBalanceLoading && balanceErrorMsg && <DataLoadingFailed bgColor="none" sx={{ px: 2 }} />}

      {!balanceErrorMsg && balanceCardsData !== undefined && openBalancePage && (
        <BalanceMobilePage reportingDate={reportingDate} cardsData={balanceCardsData} onClose={onBalancePageClose} />
      )}
    </Stack>
  );
};

export default BalanceMobileWidget;
