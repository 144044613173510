import { CircularProgress, Stack, Typography } from "@mui/material";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import FileIcon from "../../../shared/components/FileIcon";
import DataGridGroupHeader from "../../../shared/components/grid/DataGridGroupHeader";
import { convertISODate } from "../../../shared/utilities/dateUtils";
import { formatFileSize, getFileExtension } from "../../../shared/utilities/fileHelper";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import { useFundraisingDocumentActionsContext } from "./FundraisingDocumentActionsContext";

const FileNameCell = ({ row }: { row: FundraisingFile }) => {
  const { downloadingFileId, showFilePreview } = useFundraisingDocumentActionsContext();

  if (!row.name) {
    return null;
  }

  const preventDownload = row.accessOptions !== undefined && !row.accessOptions.includes("Download");

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%" pl={3}>
      {downloadingFileId === row.fileId ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <FileIcon fileExtension={getFileExtension(row.name)} />
      )}

      <Typography
        noWrap
        sx={(t) => ({ cursor: "pointer", "&:hover": { color: t.palette.primary.main } })}
        onClick={() => showFilePreview(row.fileId, preventDownload)}
      >
        {row.name}
      </Typography>
    </Stack>
  );
};

export const getGridColumnVisibilityModel = ({ showSize }: { showSize: boolean }) => ({
  name: false,
  section: false,
  size: showSize,
});

export const gridRowGroupingModel = ["section"];

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingFile> = {
  leafField: "name",
  hideDescendantCount: true,
  flex: 1,
  renderHeader: (params) => <DataGridGroupHeader params={params} />,
};

export const columnDefinitions: GridColDef<FundraisingFile>[] = [
  {
    field: "section",
    renderCell: ({ value }) => <Typography variant="subtitle2">{value}</Typography>,
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: ({ row }) => <FileNameCell row={row} />,
  },
  {
    field: "size",
    headerName: "Size",
    type: "number",
    width: 80,
    valueFormatter: (value: number) => (value ? formatFileSize(value) : null),
  },
  {
    field: "publishedAt",
    headerName: "Date",
    width: 100,
    valueFormatter: (value: string) => (value ? convertISODate(value) : ""),
  },
];
