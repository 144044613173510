import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { SetStateAction } from "react";
import CountryFlag from "../../../../../shared/components/CountryFlag";
import { countryCodeAlpha2Lookup } from "../../../../../shared/utilities/countriesProvider";
import { useSettingsContext } from "../../SettingsContext";
import { AccountSettingsFormState, updateFieldAction } from "./accountSettingsEditState";

interface Props {
  formState: AccountSettingsFormState;
  setFormState: React.Dispatch<SetStateAction<AccountSettingsFormState>>;
  allowEdit: boolean;
}

const EditAccountSettingsForm = ({ formState, setFormState, allowEdit }: Props) => {
  const { dictionaries } = useSettingsContext();

  const countriesOptions = dictionaries.countries.map(({ code }) => code);
  const statesOptions = dictionaries.states.map(({ code }) => code);

  const countryCodeAlpha2 = formState.values.country
    ? countryCodeAlpha2Lookup.get(formState.values.country)
    : undefined;

  return (
    <>
      <Stack spacing={2.5} width="100%">
        <Typography variant="subtitle2">General</Typography>
        <TextField
          fullWidth
          required
          label="Name"
          variant="outlined"
          disabled={!allowEdit}
          value={formState.values.name}
          onChange={(e) => setFormState(updateFieldAction("name", e.target.value))}
          error={!!formState.errors.name}
          helperText={formState.errors.name}
        />
      </Stack>

      <Stack spacing={2.5} width="100%">
        <Typography variant="subtitle2">Address</Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            required
            fullWidth
            label="Address Line 1"
            variant="outlined"
            disabled={!allowEdit}
            value={formState.values.addressLine1}
            onChange={(e) => setFormState(updateFieldAction("addressLine1", e.target.value))}
            error={!!formState.errors.addressLine1}
            helperText={formState.errors.addressLine1}
          />
          <TextField
            fullWidth
            label="Address Line 2"
            variant="outlined"
            disabled={!allowEdit}
            value={formState.values.addressLine2}
            onChange={(e) => setFormState(updateFieldAction("addressLine2", e.target.value))}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            required
            fullWidth
            label="City"
            variant="outlined"
            disabled={!allowEdit}
            value={formState.values.city}
            onChange={(e) => setFormState(updateFieldAction("city", e.target.value))}
            error={!!formState.errors.city}
            helperText={formState.errors.city}
          />
          <Autocomplete
            fullWidth
            freeSolo
            includeInputInList
            disabled={!allowEdit}
            options={statesOptions}
            value={formState.values.state}
            onInputChange={(_e, newValue) => setFormState(updateFieldAction("state", newValue || ""))}
            renderInput={(params) => <TextField {...params} label="State / Province / Region" />}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="ZIP / Postal Code"
            variant="outlined"
            disabled={!allowEdit}
            value={formState.values.zipCode}
            onChange={(e) => setFormState(updateFieldAction("zipCode", e.target.value))}
          />
          <Autocomplete
            fullWidth
            includeInputInList
            disabled={!allowEdit}
            options={countriesOptions}
            value={formState.values.country}
            onChange={(_e, newValue) => setFormState(updateFieldAction("country", newValue || ""))}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Country"
                error={!!formState.errors.country}
                helperText={formState.errors.country}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: countryCodeAlpha2 ? (
                      <CountryFlag countryCodeAlpha2={countryCodeAlpha2} typographyProps={{ ml: 1 }} />
                    ) : undefined,
                  },
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default EditAccountSettingsForm;
