import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreviewFileDialog from "../../../../shared/components/previewFile/PreviewFileDialog";
import { convertISODateTimeWithSeconds } from "../../../../shared/utilities/dateUtils";
import { FileInfo } from "../../../api/types/commonApiTypes";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import { getFundFromMessage, getRecipientsFromMessage } from "../../../store/state/messages/messageHelper";
import { currentMessageSelector, markAsImportantUpdatingSelector } from "../../../store/state/messages/selectors";
import { markAsImportant } from "../../../store/state/messages/thunks";
import { impersonationSelector } from "../../../store/state/user/selectors";
import { AppDispatch } from "../../../store/store";
import { MessageLevelComponent } from "../messageLevel/MessageLevelComponent";
import AttachmentsContainer from "./AttachmentsContainer";

interface Props {
  downloadAttachment: (messageId: string, attachmentId: string) => void;
}

export const Content = (props: Props) => {
  const { downloadAttachment } = props;

  const [previewFileData, setPreviewFileData] = useState<FileInfo | undefined>(undefined);

  const dispatch: AppDispatch = useDispatch();
  const message = useSelector(currentMessageSelector);
  const applyingImportance = useSelector(markAsImportantUpdatingSelector);
  const isImpersonation = useSelector(impersonationSelector);

  const { ui } = useInboxStateContext();

  const changeImportance = useCallback(() => {
    if (message) {
      dispatch(markAsImportant([message.id], !message.isImportant));
    }
  }, [message, dispatch]);

  if (message === undefined) {
    return null;
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Stack width="100%" p={2.5} spacing={1.5}>
        <Typography variant="subtitle2">{getFundFromMessage(message)}</Typography>
        <Stack width="100%" spacing={1} alignItems="center">
          <MessageLevelComponent level={message.messageLevel} value={message.categoryId} />
          <Typography variant="caption" color={"secondary"}>
            {convertISODateTimeWithSeconds(message.sendDate)}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">To: {getRecipientsFromMessage(message)}</Typography>
      </Stack>

      <Divider />

      <Box width="100%" display="flex" flexDirection="column" px={2.5} py={2}>
        <Box width="100%" display="flex" alignItems="center">
          <Typography variant="h5">{message.content.subject}</Typography>
          <IconButton
            disabled={applyingImportance}
            onClick={() => {
              if (!isImpersonation) {
                changeImportance();
              }
            }}
          >
            <LabelImportantIcon style={{ color: message?.isImportant ? "#FFB400" : "inherit" }} />
          </IconButton>
        </Box>
        <Box dangerouslySetInnerHTML={{ __html: message.content.htmlBody || "" }}></Box>
        <AttachmentsContainer
          message={message}
          loading={ui.messageDetailLoading}
          onPreview={(downloadData) => {
            setPreviewFileData(downloadData);
            window.scrollTo(0, 0);
          }}
          onDownloadAttachment={downloadAttachment}
        />
      </Box>

      {previewFileData && message && !ui.messageDetailLoading && (
        <PreviewFileDialog
          url={previewFileData.downloadUrl}
          fileName={previewFileData.fileName}
          documentViewSessionId={previewFileData.documentViewSessionId}
          onClose={() => setPreviewFileData(undefined)}
        />
      )}
    </Box>
  );
};

export default Content;
