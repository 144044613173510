import { useScrollTrigger } from "@mui/material";
import { cloneElement, JSX } from "react";

export const ElevationHeader = (props: { children: JSX.Element }) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
};

export default ElevationHeader;
