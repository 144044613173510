import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import { getInboxStateFromTag } from "../../../hooks/inboxState/inboxState.utils";
import { inboxStructureSelector } from "../../../store/state/inboxStructure/selectors";
import { InboxCategoryTags } from "../../../store/state/inboxStructure/types";
import { systemTags } from "../../../store/state/messages/types";
import CategoryMenu from "./generalMenu/CategoryMenu";
import InvestorMenu from "./investorMenu/InvestorMenu";

interface Props {
  onItemSelected?: () => void;
}

const InboxTreeComponent = ({ onItemSelected }: Props) => {
  const { tag: tagParam } = useParams();
  const { updateCriteria, criteria } = useInboxStateContext();
  const inboxStructure = useSelector(inboxStructureSelector);

  const categories = useMemo(() => {
    const categoryTags = inboxStructure["categoryTags"];
    return ((categoryTags ?? []) as InboxCategoryTags[]).map((c) => ({ key: c.id, value: c.name }));
  }, [inboxStructure]);

  const selectMenu = useCallback(
    (tag: string) => {
      onItemSelected?.();
      const isImportant = tag === systemTags.Important || undefined;
      updateCriteria({
        ...getInboxStateFromTag(tag, categories),
        isImportant,
        page: 0,
        investors: [],
        funds: [],
        entities: [],
      });
    },
    [onItemSelected, updateCriteria, categories]
  );

  const selectInvestor = useCallback(
    (tag: string) => {
      onItemSelected?.();
      updateCriteria({
        ...getInboxStateFromTag(tag, []),
        page: 0,
        categories: [],
        entities: [],
        isImportant: undefined,
        selectedInvestorTag: tag,
      });
    },
    [onItemSelected, updateCriteria]
  );

  if (Object.keys(inboxStructure).length === 0) {
    return (
      <Box sx={{ display: "flex", flex: 1 }}>
        <InlineLoader />
      </Box>
    );
  }

  const selectedCategory = categories.find((c) => c.key === tagParam)?.value;
  const selectedInvestor = selectedCategory ? "" : tagParam || criteria.selectedInvestorTag || "";

  return (
    <Box width="100%" display="flex" flexDirection="column" pl={2} pr={1} py={3}>
      <CategoryMenu
        selectedItem={selectedCategory || tagParam || ""}
        inboxStructure={inboxStructure}
        onItemSelected={selectMenu}
      />
      <InvestorMenu selectedItem={selectedInvestor} inboxStructure={inboxStructure} onItemSelected={selectInvestor} />
    </Box>
  );
};

export default InboxTreeComponent;
