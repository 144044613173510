import { Container, Paper } from "@mui/material";
import ScrollableFlexContainer from "../../../shared/components/ScrollableFlexContainer";
import PortfolioReportView from "./PortfolioReportView";

const PortfolioPage = () => {
  return (
    <Container maxWidth="xl" sx={{ pb: 1, height: "100%", py: 2 }}>
      <ScrollableFlexContainer sx={{ height: "100%" }}>
        <Paper variant="outlined" sx={{ flex: 1, py: 2, px: 5 }}>
          <PortfolioReportView />
        </Paper>
      </ScrollableFlexContainer>
    </Container>
  );
};

export default PortfolioPage;
