import { Theme, useMediaQuery } from "@mui/material";
import { useCallback, useMemo } from "react";
import DataGrid from "../../../shared/components/grid/DataGrid";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import {
  columnDefinitions,
  getGridColumnVisibilityModel,
  gridRowGroupingModel,
  groupingColumnDefinition,
} from "./fundraisingDocumentsGridDataProvider";

interface Props {
  files: FundraisingFile[];
}

const FundraisingDocumentsGrid = ({ files }: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const columnVisibilityModel = useMemo(
    () => getGridColumnVisibilityModel({ showSize: !isSmallScreen }),
    [isSmallScreen]
  );

  const isGroupExpandedByDefault = useCallback(() => true, []);

  return (
    <DataGrid<FundraisingFile>
      getRowId={(row) => row.fileId}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rows={files}
      columnHeaderHeight={isSmallScreen ? 0 : 36}
      disableColumnResize
      disableColumnPinning
      disableColumnSorting
      disableColumnReorder
      disableMultipleRowSelection
      isGroupExpandedByDefault={isGroupExpandedByDefault}
      rowGroupingModel={gridRowGroupingModel}
      columnVisibilityModel={columnVisibilityModel}
      sx={{
        ".MuiDataGrid-columnHeaderTitle": { color: "text.secondary" },
      }}
      noScrollableContainer
      scrollbarSize={0}
    />
  );
};

export default FundraisingDocumentsGrid;
