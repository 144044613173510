import { SwipeableDrawer } from "@mui/material";
import SwipePuller from "../../../../shared/components/SwipePuller";
import { SwipeableFiltersDrawerProps } from "./types";

const SwipeableFiltersDrawer = ({ open, onClose, children }: SwipeableFiltersDrawerProps) => {
  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      disableSwipeToOpen
      slotProps={{
        backdrop: { onClick: onClose },
      }}
      onClose={onClose}
      onOpen={() => undefined}
      swipeAreaWidth={20}
      sx={{
        zIndex: 1300,
        "& .MuiDrawer-paper": {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      }}
    >
      <SwipePuller />
      {children}
    </SwipeableDrawer>
  );
};

export default SwipeableFiltersDrawer;
